.bride-and-groom-container {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;

  flex-direction: column;
}

.bride-and-groom-fountain-and-flowers {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.bride-groom-fountain-flower-front {
  top: 0%;
  animation: breezeLeftToRight 5s infinite alternate ease-in-out;
}

.name-text {
  color: #022b38;
  font-family: "Slight";
  font-size: 60px;
  transform: rotate(-10deg);
  align-self: flex-start;
  margin-bottom: 30px;
}

.name-text.active.bride {
  animation: slideFromLeft 2s forwards;
}
.name-text.active.groom {
  animation: slideFromRight 2s forwards;
}

.name-container {
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
}
.name-detail {
  color: #022b38;
  font-family: "Caudex";
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 20px;
  text-align: right;
}
.name-detail.active.bride {
  animation: slideFromLeft 2s forwards;
  padding-right: 24px;
}
.name-detail.active.groom {
  animation: slideFromRight 2s forwards;
  padding-right: 24px;
}

.bride-groom-photo {
  margin-top: 10%;
  height: 40%;
  position: relative;
  display: flex;
  justify-content: center;
}

.photo {
  border-radius: 50%;
  border: 2px solid #022b38;
  width: 200px;
  height: 300px;
}

.bride-groom-photo-flower {
  width: 65%;
  max-width: 300px;
  position: absolute;
  bottom: -50px;
  left: 50px;
  animation: breezeLeftToRight 5s infinite alternate ease-in-out;
}
.bride-groom-title {
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: "Cammron";
  font-size: 56px;
  color: #022b38;
  line-height: 0.75;
}
.bride-groom-title.groom.active {
  align-items: start;
  left: 0;
  right: auto;
  animation: slideFromLeft 2s forwards;
}
.bride-groom-title.bride.active {
  animation: slideFromRight 2s forwards;
}

.bride-groom-photo .photo.active {
  animation: zoom-in-zoom-out 5s;
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .bride-groom-title {
    font-size: 72px;
  }

  .bride-groom-photo-flower {
    bottom: -70px;
  }

  .photo {
    width: 180px;
    height: 250px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .name-container {
    margin-top: 12%;
  }

  .name-text.active {
    font-size: 80px;
  }

  .name-detail {
    font-size: 18px;
    line-height: 32px;
    margin-top: -5%;
    margin-right: 5%;
  }

  .bride-groom-title {
    font-size: 100px;
  }

  .bride-groom-photo-flower {
    width: 100%;
    bottom: -130px;
    left: 25vw;
  }
  .photo {
    width: 300px;
    height: 450px;

    margin-top: 0%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .name-container {
    margin-top: 12%;
  }

  .name-text.active {
    font-size: 90px;
  }

  .name-detail {
    font-size: 24px;
    line-height: 32px;
    margin-top: -5%;
    margin-right: 5%;
  }

  .bride-groom-title {
    font-size: 100px;
  }

  .bride-groom-photo {
    margin-top: 20%;
  }

  .bride-groom-photo-flower {
    width: 100%;
    bottom: -130px;
    left: 25vw;
  }
  .photo {
    width: 300px;
    height: 450px;

    margin-top: 0%;
  }
}
