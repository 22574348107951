@import "../../helper/keyframe.css";

.venue-page {
  display: flex;
  flex-direction: column;
  background-color: #f9f8f5;
  height: 100vh;
  width: 100vw;
  color: #022b38;
  position: relative;
  overflow: hidden;
}

.fountain-and-trees {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.location-text {
  font-family: "Slight";
  font-size: 48px;
  transform: rotate(-10deg);
  margin-bottom: 60px;
  align-self: flex-start;
  margin-top: 15%;
  margin-left: -100%;
  transition: margin-left 2s ease;
  z-index: 2;
}

.location-text.active {
  margin-left: 0;
}

.phalosa-text-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 4px;
}

.phalosa-text {
  opacity: 0;
  transform: translateY(100px);
  transition: transform 1s ease, opacity 1s ease;
}

.phalosa-text.active {
  opacity: 1;
  transform: translateY(0px);
}

.address-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 2;
}

.schedule {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  letter-spacing: 3px;
}

.holy-matrimony {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  margin-bottom: 24px;
  gap: 4px;
  opacity: 0;
  transform: translateY(100px);
  transition: transform 1s ease, opacity 1s ease;
}

.holy-matrimony.active {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 1s;
}

.reception {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  margin-bottom: 40px;
  gap: 4px;
  opacity: 0;
  transform: translateY(100px);
  transition: transform 1s ease, opacity 1s ease;
}

.reception.active {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 2s;
}

.location-button {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 24px;
}

.button-address {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 3px;
  border-radius: 25px;
  color: #fff;
  background-color: #022b38;
  border-color: #022b38;
  width: 55vw;
  height: 50px;
  font-family: "Caudex";
  background-position: center;
  transition: background 0.8s;
  transform: scale(0, 0);
}

.button-address.active {
  animation: pop-out 2s forwards;
}

.button-address:hover {
  background: #022b38 radial-gradient(circle, transparent 1%, #022b38 1%)
    center/15000%;
}

.button-address:active {
  background-color: white;
  background-size: 100%;
  transition: background 0s;
}

.presave-text {
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 3px;
  transition: opacity 1s ease;
  transition-delay: 4s;
}

.presave-text.active {
  opacity: 1;
  animation: typing 1.5s steps(40, end);
  animation-delay: 4s;
}

.fountain-location {
  position: absolute;
  bottom: -100%;
  opacity: 0;
  transition: bottom 5s ease, opacity 1s ease;
  z-index: 1;
  width: 65vw;
}

.fountain-location.active {
  bottom: 0%;
  opacity: 1;
}

.tree-left {
  position: absolute;
  bottom: 0%;
  left: -100%;
  opacity: 0;
  transition: left 2s ease, opacity 1s ease;
  width: 35vw;
}

.tree-left.top {
  animation: sway 6s infinite;
}

.tree-left.bottom {
  animation: breezeLeftToRight 3s infinite alternate ease-in-out;
}

.tree-left.active {
  left: 0%;
  opacity: 1;
}

.tree-right {
  position: absolute;
  bottom: 0%;
  right: -100%;
  opacity: 0;
  transition: right 2s ease, opacity 1s ease;
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.tree-right-top {
  animation: breezeLeftToRight 4s infinite alternate ease-in-out;
  margin-bottom: 60px;
}

.tree-right-mid {
  position: absolute;
  bottom: 15vh;
  animation: sway 4s infinite alternate ease-in-out;
}

.tree-right-bottom {
  z-index: 2;
  animation: breezeRightToLeft 3s infinite alternate ease-in-out;
  margin-bottom: -30%;
}

.tree-right.active {
  right: 0%;
  opacity: 1;
}

@media only screen and (max-width: 380px) {
  .phalosa-text-container {
    font-size: 14px;
    margin-bottom: 36px;
  }

  .schedule {
    font-size: 10px;
  }

  .holy-matrimony {
    flex-direction: row;
    gap: 4px;
  }

  .reception {
    flex-direction: row;
    gap: 4px;
  }

  .button-address {
    height: 42px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .location-text {
    margin-top: 10%;
    font-size: 72px;
    margin-bottom: 90px;
  }

  .phalosa-text-container {
    font-size: 24px;
    gap: 12px;
    margin-bottom: 32px;
  }

  .street-text-container {
    font-size: 16px;
  }

  .holy-matrimony {
    font-size: 16px;
  }

  .reception {
    font-size: 16px;
    gap: 8px;
  }

  .presave-text {
    font-size: 16px;
  }

  .button-address {
    width: 350px;
    padding: 12px 24px;
    border-radius: 40px;
    font-size: 24px;
    height: 72px;
  }

  .tree-left {
    width: 35vw;
  }

  .tree-right {
    width: 30vw;
  }

  .tree-right-mid {
    bottom: 22vh;
    width: 30vw;
  }

  .tree-right-top {
    width: 30vw;
    bottom: 40vh;
  }

  .fountain-location {
    width: 50vw;
  }
}
