@keyframes slideLeftDisappear {
  0% {
    right: 0;
  }
  100% {
    right: 100%;
  }
}

@keyframes slideRightDisappear {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@keyframes slideRight {
  0% {
    left: 0;
  }
  50% {
    left: 25%;
  }
  100% {
    left: 0;
  }
}

@keyframes appearTop {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    right: 0;
  }
  50% {
    right: 25%;
  }
  100% {
    right: 0;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes breezeLeftToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-10px);
  }
}

@keyframes breezeRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10px);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes sway {
  0% {
    transform: rotateZ(-2deg);
  }
  50% {
    transform: rotateZ(2deg);
  }
  100% {
    transform: rotateZ(-2deg);
  }
}

@keyframes openLeftCurtain {
  0% {
    margin-left: 0%;
  }
  100% {
    margin-left: -250%;
  }
}

@keyframes openRightCurtain {
  0% {
    margin-right: 0%;
  }
  100% {
    margin-right: -250%;
  }
}

@keyframes closeLeftCurtain {
  0% {
    margin-left: -250%;
  }
  100% {
    margin-left: 0%;
  }
}

@keyframes closeRightCurtain {
  0% {
    margin-right: -250%;
  }
  100% {
    margin-right: -0%;
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0, 0);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes move-arrow {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(9px);
  }
  66.6% {
    opacity: 1;
    transform: translateY(12px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px) scale(0.5);
  }
}

@keyframes slideFromLeft {
  0% {
    margin-left: -100%;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes slideFromRight {
  0% {
    margin-right: -100%;
  }
  100% {
    margin-right: 0;
  }
}

@keyframes pop-out {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes meetInTheMiddleRight {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes meetIntheMiddleLeft {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes floatEasy {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}
