.gallery-header {
  padding-top: 1rem;
  font-size: 2rem;
}

.gallery-container {
  height: max-content;
  overflow: hidden;
  background-image: url(https://res.cloudinary.com/dheb80jjm/image/upload/v1716900249/wed-cyn-cyr-2/background-image_eqnkfh.jpg);
}

.alt-text {
  color: white;
  text-align: center;
  margin-top: -50px;
  margin-bottom: 50px;
}

.header-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.h1 {
  margin-top: 32px;
  color: #022b38;
}

.h2 {
  color: #022b38;
}

.h3 {
  font-size: small;
  color: #022b38;
}

.img-container {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  align-items: center;
}

.img-gallery {
  width: 100vw;
}

.img-card-gallery {
  margin: 0.8rem;
}

.img-card-gallery-2 {
  margin: 0 0.5rem;
}

.img-card-gallery-3 {
  width: 100%;
}

.mt-1 {
  margin-top: 2rem;
}

.mt-2 {
  margin-top: 4rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: -5px;
}

.card-text-wrapper {
  writing-mode: horizontal-tb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: small;
}

.duo-image {
  display: flex;
  flex-direction: row;
}

.trio-image {
  display: flex;
  flex-direction: row;
}

.solo-card {
  width: 45vw;
}

.duo-card {
  width: 35vw;
}

.trio-card {
  width: 24vw;
  margin: 0.4rem;
}

.caption-container {
  justify-content: center;
  position: absolute;
  width: 100%;
  margin-top: 12px;
}

.title-text {
  font-size: 18px;
  color: white;
  text-align: center;
}

.alt-text-2 {
  color: white;
  text-align: center;
  font-size: 14px;
  margin-top: 4px;
}

@media only screen and (min-width: 321px) and (max-width: 379px) {
  .title-text {
    font-size: 16px;
  }

  .alt-text-2 {
    font-size: 12px;
    margin-top: 4px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .title-text {
    font-size: 48px;
  }

  .alt-text-2 {
    font-size: 24px;
    margin-top: 4px;
  }
}
