.gift-page {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  color: #022b38;
  background-image: url(https://res.cloudinary.com/dheb80jjm/image/upload/v1716900249/wed-cyn-cyr-2/background-image_eqnkfh.jpg);
}

.wishing-well-text-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  margin-top: 10%;
  width: 75vw;
  transform: rotate(-10deg);
  margin-left: 20px;
  margin-bottom: 40px;
}

.wishing-well-text {
  font-family: "Slight";
  font-size: 48px;
  align-self: flex-start;
}

.wishing-well-text.top {
  margin-left: -100%;
  padding-top: 10px;
}

.wishing-well-text.top.active {
  animation: slideFromLeft 2s forwards;
}

.wishing-well-text.bottom {
  margin-right: -100%;
  align-self: flex-end;
}

.wishing-well-text.bottom.active {
  animation: slideFromRight 2s forwards;
}

.gift-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 3px;
  padding-left: 40px;
  padding-right: 40px;
}

.gift-text {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
}

.gift-text.top {
  opacity: 0;
  margin-bottom: 20px;
  transform: translateX(100px);
  transition: transform 1s ease, opacity 1s ease;
}

.gift-text.top.active {
  opacity: 1;
  transform: translateY(0);
}

.gift-text.bottom {
  opacity: 0;
  margin-bottom: 60px;
  transform: translateX(100px);
  transition: transform 1s ease, opacity 1s ease;
  transition-delay: 0.5s;
}

.gift-text.bottom.active {
  opacity: 1;
  transform: translateY(0);
}

.bank-account {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
}

.bank-account.top {
  opacity: 0;
  margin-bottom: 40px;
}

.bank-account.bottom {
  opacity: 0;
}

.bank-account.top.active {
  animation-name: fadeRight;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.bank-account.bottom.active {
  animation-name: fadeRight;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}

.briefcases {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.briefcase-top {
  position: absolute;
  bottom: 15%;
  opacity: 0;
  margin-left: -100%;
  transition: margin-left 1s ease, opacity 1s ease;
}

.briefcase-middle {
  position: absolute;
  bottom: 8%;
  opacity: 0;
  margin-right: -100%;
  transition: margin-right 1s ease, opacity 1s ease;
}

.briefcase-bottom {
  position: absolute;
  bottom: 0;
  opacity: 0;
  margin-left: -100%;
  transition: margin-left 1s ease, opacity 1s ease;
}

.briefcase-top.active {
  margin-left: 0;
  opacity: 1;
}

.briefcase-middle.active {
  margin-right: 0;
  opacity: 1;
}

.briefcase-bottom.active {
  margin-left: 0;
  opacity: 1;
}

.gift-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.money {
  font-family: "Caudex";
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  background-color: #022b38;
  border-color: #022b38;
  border-radius: 20px;
  min-height: 50px;
  width: calc(100% - 40px);
}

.money.aud-button {
  background-color: #fff;
  border-color: #022b38;
  color: #022b38;
}

.money.idr-button {
  margin-left: -100%;
}

.money.idr-button.active {
  animation: slideFromLeft 2s forwards;
}

.money.aud-button {
  margin-right: -100%;
}

.money.aud-button.active {
  animation: slideFromRight 2s forwards;
}

.custom-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
}

.custom-modal {
  width: 240px;
  height: 320px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  border: 3px solid #022b38;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.currency {
  margin-left: auto;
}

.close-button {
  margin-left: auto;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Caudex";
  font-size: 12px;
  letter-spacing: 1px;
}

.modal-content.aud {
  height: 100%;
}

.barcode-idr {
  margin-top: 16px;
  margin-bottom: 16px;
}

.account-number {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 10px;
}

.copy-button {
  font-family: "Caudex";
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  background-color: #022b38;
  border-color: #022b38;
  border-radius: 20px;
  min-height: 40px;
  margin-top: 20px;
  width: calc(100% - 40px);
}

.tooltip {
  background-color: white;
  color: green;
  border: none;
  padding: 4px 8px;
  margin-top: 5px;
  border-radius: 4px;
}

.tooltip.failed {
  color: red;
}

@media only screen and (max-width: 380px) {
  .gift-text.bottom {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .wishing-well-text-container {
    width: 60vw;
    margin-left: 40px;
    margin-top: 10%;
    margin-bottom: 60px;
  }

  .wishing-well-text {
    font-size: 72px;
  }

  .gift-text {
    font-size: 18px;
  }

  .bank-account {
    font-size: 18px;
  }

  .briefcase-top {
    width: 250px;
    bottom: 17%;
  }

  .briefcase-middle {
    width: 300px;
    bottom: 9%;
  }

  .briefcase-bottom {
    width: 320px;
  }

  .money {
    width: 60%;
  }

  .gift-page-content {
    padding-left: 80px;
    padding-right: 80px;
  }

  .custom-modal {
    width: 480px;
    height: 640px;
  }

  .currency {
    font-size: 24px;
  }

  .close-button {
    font-size: 24px;
  }

  .modal-content {
    font-size: 24px;
  }

  .barcode-idr {
    margin-top: 32px;
    margin-bottom: 32px;
    width: 240px;
    height: 240px;
  }

  .account-number {
    font-size: 28px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .copy-button {
    font-size: 24px;
    margin-top: 40px;
    min-height: 60px;
    width: 80%;
  }
}
