@import "../../helper/keyframe.css";

.date-page {
  position: relative;
  overflow: hidden;
  flex-direction: column;
  background-image: url(https://res.cloudinary.com/dheb80jjm/image/upload/v1716900247/wed-cyn-cyr-2/background-date_lmxpwe.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100vw;
  align-items: center;
  color: #022b38;
  display: flex;
}

.event-date-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 32px;
}

.logo {
  width: 40px;
  margin-bottom: 32px;
}

.invitate-title {
  font-size: 10px;
  letter-spacing: 3px;
}

.bride-name,
.groom-name {
  width: 300px;
}

.bride-groom-name {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.name-separator {
  font-size: 10px;
  letter-spacing: 3px;
  margin-bottom: 10px;
}

.event-date-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30%;
  opacity: 0;
}

.event-date-page-content.active {
  opacity: 1;
  animation: zoom-in-zoom-out 5s;
}

.on-text {
  font-size: 10px;
  margin-bottom: 24px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  letter-spacing: 3px;
  gap: 10px;
}

.fountain-and-flowers {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.fountain {
  position: absolute;
  bottom: -50%;
  opacity: 0;
  transition: bottom 5s ease, opacity 1s ease;
  z-index: 1;
}

.fountain.active {
  bottom: 0%;
  opacity: 1;
}

.flowers-behind {
  z-index: 0;
  display: flex;
  position: absolute;
  bottom: -50%;
  align-items: flex-end;
  flex-direction: row;
  opacity: 0;
  transition: bottom 5s ease, opacity 1s ease;
  transition-delay: 2s;
}

.flowers-behind.active {
  opacity: 1;
  bottom: 0%;
}

.flowers-front {
  z-index: 2;
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: flex-end;
  bottom: -50%;
  opacity: 0;
  transition: bottom 5s ease, opacity 1s ease;
  transition-delay: 1s;
}

.flowers-front.active {
  bottom: 0%;
  opacity: 1;
}

.fountain-flower-back {
  animation: breezeRightToLeft 6s infinite alternate ease-in-out;
}

.flower-border-top {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100vw;
  position: absolute;
}

.blue-flower-right {
  width: 50vw;
  margin-top: -200px;
  height: 250px;
  transition: margin-top 5s;
  animation: breezeRightToLeft 4s infinite alternate ease-in-out;
}

.blue-flower-right.active {
  margin-top: 0px;
}

.blue-flower-left {
  width: 50vw;
  height: 250px;
  margin-top: -200px;
  transition: margin-top 5s;
  animation: breezeLeftToRight 5s infinite alternate ease-in-out;
}

.blue-flower-left.active {
  margin-top: 0px;
}

.flipper {
  perspective: 1000px;
}

.flipper-inner {
  width: 100%;
  transform-style: preserve-3d;
  transition: transform 2s;
  transition-delay: 3s;
}

.flipper-inner.active {
  transform: rotateY(180deg);
}

.flipper-front,
.flipper-back {
  width: 100%;
  backface-visibility: hidden;
}

.flipper-back {
  transform: rotateY(180deg);
}

@media only screen and (max-width: 380px) {
  .event-date-page-content {
    margin-top: 20%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .fountain-and-flowers {
    margin-top: 300px;
  }

  .event-date-title {
    margin-bottom: 32px;
  }

  .logo {
    width: 80px;
  }

  .invitate-title {
    font-size: 16px;
  }

  .bride-name,
  .groom-name {
    width: 600px;
  }

  .on-text {
    margin-top: 32px;
    font-size: 16px;
    margin-bottom: 48px;
  }

  .name-separator {
    font-size: 16px;
  }

  .blue-flower-right,
  .blue-flower-left {
    height: 350px;
    width: 350px;
  }

  .fountain {
    width: 300px;
  }

  .fountain-flower-back {
    height: 150px;
  }

  .event-date-page-content {
    margin-top: 25%;
  }
}
