@import '../../helper/keyframe.css';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.flower-border-container {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 85vh;
  overflow: hidden;
  width: 100vw;
  z-index: 4;
  animation: floatEasy 5s infinite alternate ease-in-out;
}

.flower-front-container {
  position: absolute;
  display: flex;
  justify-content: center;
  overflow: hidden;
  top: 190vh;
  width: 100vw;
  z-index: 99;
  animation: breezeLeftToRight 5s infinite alternate ease-in-out;
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .flower-border {
    width: 400px;
  }

  .flower-front {
    width: 350px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .flower-border {
    width: 1000px;
  }

  .flower-front {
    width: 525px;
  }
  
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .flower-border {
    width: 1000px;
  }

  .flower-front {
    width: 600px;
  }

}
