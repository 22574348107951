.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.form-group.error .input-field {
  border-bottom: 2px solid #a60421;
}
.input-field {
  background: none;
  outline: none;
  border: none;
  border-bottom: 2px solid #022b38;
  padding-bottom: 8px;
  font-family: "Caudex", sans-serif;
}
.input-field-sublabel {
  margin-top: -3px;
  font-size: 10px;
}
label {
  font-size: 12px;
}
.form-group.error label {
  color: #a60421;
}
.form-group.error .input-field-sublabel {
  color: #a60421;
}

sup {
  color: #a60421;
}
