@import "../../helper/keyframe.css";

.not-found-page {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(https://res.cloudinary.com/dheb80jjm/image/upload/v1716900249/wed-cyn-cyr-2/background-image_eqnkfh.jpg);
  background-size: contain;
  height: 100vh;
  width: 100vw;
}

.clouds-not-found {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 3;
  align-items: flex-end;
  position: absolute;
}

.cloud-left {
  width: 550px;
  height: 250px;
}

.cloud-right {
  width: 550px;
  height: 200px;
}

.balloons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 20%;
}

.balloon-left {
  width: 350px;
  z-index: 2;
  animation: float 11s infinite linear;
}

.balloon-right {
  width: 200px;
  align-self: flex-end;
  animation: float 8s infinite linear;
}

.cloud-bottom-container-not-found {
  position: absolute;
  bottom: -10%;
}

.cloud-bottom-not-found {
  width: 1200px;
  height: 600px;
}

.headline-not-found {
  position: absolute;
  top: 25%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline-title-not-found {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-self: center;
  transform: rotate(-10deg);
  padding-top: 20px;
  gap: 10px;
}

.headline-title-not-found-top {
  display: flex;
  flex-direction: row;
}

.headline-title-not-found-bot {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.title-1,
.title-2 {
  font-family: "Slight";
  font-size: 64px;
}

.seagull-right {
  width: 200px;
  height: 80px;
  animation: float 8s infinite linear;
}

.seagull-left {
  width: 200px;
  height: 180px;
  animation: float 8s infinite linear;
}

.not-found-text-container {
  width: 600px;
  height: 100px;
  background-color: #f4f0ec;
  border-radius: 20px;
  border-color: #022b38;
  border: 3px solid;
  z-index: 3;
  margin-top: 40px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.not-found-text {
  font-size: 20px;
  letter-spacing: 2px;
  text-wrap: balance;
}

.not-found-logo {
  display: flex;
  flex-direction: column;
  font-family: "Title", sans-serif;
  font-size: 32px;
  color: #022b38;
  z-index: 3;
  position: fixed;
  padding-right: 20px;
  padding-bottom: 20px;
  bottom: 0%;
  right: 0%;
}

.not-found-logo span:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  margin-right: 40px;
  transform: rotate(-10deg);
}

.not-found-logo span:nth-child(2) {
  line-height: 2px;
  font-size: 24px;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  transform: rotate(-10deg);
}
.not-found-logo span:nth-child(3) {
  line-height: 60px;
  display: flex;
  margin-right: 20px;
  justify-content: flex-end;
  transform: rotate(-10deg);
}

.not-found-footer {
  display: flex;
  gap: 60px;
  justify-content: flex-start;
  width: 100%;
}

.not-found-button {
  cursor: pointer;
  color: white;
  font-family: "Caudex";
  letter-spacing: 2px;
  background-color: #022b38;
  border-color: #022b38;
  height: 50px;
  width: 350px;
  border-radius: 20px;
  font-size: 16px;
  margin-top: 20px;
}

@media only screen and (max-width: 320px) {
  .balloon-left {
    width: 150px;
  }

  .balloon-right {
    width: 100px;
  }

  .cloud-left {
    width: 200px;
    height: 100px;
  }

  .cloud-right {
    width: 150px;
    height: 100px;
  }

  .title-1,
  .title-2 {
    font-size: 30px;
  }

  .seagull-right {
    width: 100px;
    height: 50px;
  }

  .not-found-text-container {
    width: 280px;
    height: 50px;
    border-radius: 15px;
    border: 2px solid;
  }

  .not-found-text {
    font-size: 10px;
    letter-spacing: 2px;
  }

  .cloud-bottom-not-found {
    width: 600px;
    height: 300px;
  }

  .cloud-bottom-container-not-found {
    bottom: 0%;
  }

  .not-found-button {
    height: 30px;
    width: 150px;
    font-size: 8px;
  }

  .not-found-footer {
    gap: 20px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 379px) {
  .cloud-left {
    width: 450px;
    height: 125px;
  }

  .cloud-right {
    margin-top: 40px;
    width: 225px;
    height: 75px;
  }

  .balloon-left {
    width: 200px;
  }

  .balloon-right {
    width: 140px;
  }

  .title-1,
  .title-2 {
    font-size: 32px;
  }

  .seagull-right {
    width: 100px;
    height: 50px;
  }

  .not-found-text-container {
    width: 320px;
    height: 75px;
    border-radius: 15px;
    border: 2px solid;
  }

  .not-found-text {
    font-size: 12px;
    letter-spacing: 2px;
  }

  .cloud-bottom-not-found {
    width: 700px;
    height: 400px;
  }

  .cloud-bottom-container-not-found {
    bottom: 0%;
  }

  .not-found-button {
    height: 40px;
    width: 200px;
    font-size: 10px;
  }
}

@media only screen and (min-width: 380px) and (max-width: 480px) {
  .cloud-left {
    width: 500px;
    height: 150px;
  }

  .cloud-right {
    margin-top: 60px;
    width: 250px;
    height: 75px;
  }

  .balloon-left {
    width: 220px;
  }

  .balloon-right {
    width: 150px;
  }

  .cloud-bottom-not-found {
    width: 700px;
    height: 400px;
  }

  .title-1,
  .title-2 {
    font-size: 42px;
  }

  .seagull-right {
    width: 100px;
    height: 50px;
  }

  .not-found-text-container {
    width: 350px;
    height: 75px;
    border-radius: 15px;
    border: 2px solid;
  }

  .not-found-text {
    font-size: 14px;
    letter-spacing: 2px;
  }

  .cloud-bottom-container-not-found {
    bottom: 0%;
  }

  .not-found-button {
    height: 40px;
    width: 200px;
    font-size: 10px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .cloud-left {
    width: 600px;
    height: 200px;
  }

  .cloud-right {
    margin-top: 60px;
    width: 300px;
    height: 100px;
  }

  .balloon-left {
    width: 250px;
  }

  .balloon-right {
    width: 150px;
  }

  .cloud-bottom-not-found {
    width: 800px;
    height: 400px;
  }

  .title-1,
  .title-2 {
    font-size: 56px;
  }

  .seagull-right {
    width: 100px;
    height: 50px;
  }

  .not-found-text-container {
    width: 400px;
    height: 100px;
    border-radius: 15px;
    border: 2px solid;
  }

  .not-found-text {
    font-size: 16px;
    letter-spacing: 2px;
  }

  .cloud-bottom-container-not-found {
    bottom: 0%;
  }

  .not-found-button {
    height: 40px;
    width: 200px;
    font-size: 12px;
  }
}
