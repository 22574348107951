.radio-container {
  display: flex;
  gap: 16px;
}
input[type='radio'] {
  accent-color: #022b38;
  width: 18px;
  height: 18px;
}
.options {
  font-family: 'Caudex', sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
}
