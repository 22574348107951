@import "../../helper/keyframe.css";

.landing-page {
  position: relative;
  overflow: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(https://res.cloudinary.com/dheb80jjm/image/upload/v1716900250/wed-cyn-cyr-2/background-landing-image_wxqb0q.jpg);
  background-size: contain;
  height: var(--doc-height);
  width: 100vw;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Title", sans-serif;
  font-size: 72px;
  opacity: 0;
  transition: opacity 4s ease;
  transition-delay: 5s;
  color: #022b38;
}

.text-journey {
  font-size: 14px;
  opacity: 0;
  transition: opacity 3s ease;
  transition-delay: 4s;
  color: #022b38;
  font-weight: bold;
  letter-spacing: 4px;
}

.title-container.active,
.text-journey.active {
  opacity: 1;
}

.title-container span:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  margin-right: 40px;
  transform: rotate(-10deg);
}

.title-container span:nth-child(2) {
  line-height: 36px;
  font-size: 48px;
  display: flex;
  justify-content: flex-end;
  padding-right: 60px;
  transform: rotate(-10deg);
}
.title-container span:nth-child(3) {
  line-height: 60px;
  display: flex;
  margin-right: 20px;
  justify-content: flex-end;
  transform: rotate(-10deg);
}

.title-image {
  width: 300px;
  height: 200px;
  opacity: 0;
}

.title-image.active {
  width: 300px;
  height: 200px;
  opacity: 1;
}

.clouds,
.headline {
  z-index: 1;
}

.headline {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 5;
  margin-top: 20px;
}

.headline.disappear {
  opacity: 0;
  transition: opacity 5s ease;
}

.seagull.left {
  position: fixed;
  top: -50%;
  opacity: 0;
  transition: top 5s ease, opacity 1s ease;
  animation: float 11s infinite linear;
  z-index: 0;
  scale: -1 1;
}

.seagull.left.active {
  top: 55%;
  left: 8%;
  width: 20vw;
  opacity: 1;
}

.seagull.left.disappear {
  top: -50%;
  opacity: 0;
  transition: top 3s ease, opacity 6s ease;
}

.seagull.left {
  align-self: flex-start;
}

.seagull.right {
  position: fixed;
  bottom: 50%;
  opacity: 0;
  transition: bottom 5s ease, opacity 1s ease;
  animation: float 8s infinite linear;
  z-index: 3;
  align-self: flex-end;
}

.seagull.right.active {
  bottom: 85%;
  width: 40vw;
  opacity: 1;
}

.seagull.right.disappear {
  bottom: 100%;
  opacity: 0;
  transition: bottom 3s ease, opacity 6s ease;
}

.seagull.right-bottom {
  position: fixed;
  bottom: 0%;
  opacity: 0;
  transition: bottom 5s ease, opacity 1s ease;
  animation: float 8s infinite linear;
  z-index: 3;
  align-self: flex-end;
}

.seagull.right-bottom.active {
  bottom: 35%;
  opacity: 1;
}

.seagull.right-bottom.active.disappear {
  bottom: 100%;
  opacity: 0;
  transition: bottom 3s ease, opacity 6s ease;
}

.bottom-image {
  width: 100vw;
  margin-bottom: -10px;
  opacity: 0;
  transition: margin-bottom 5s ease, opacity 5s ease;
  z-index: 2;
}

.bottom-image.active {
  opacity: 1;
}

.bottom-image.disappear {
  margin-bottom: -100%;
  opacity: 0;
  transition: margin-bottom 3s ease, opacity 6s ease;
}

.bottom-image-container {
  position: absolute;
  bottom: 0%;
  width: 100vw;
}

.footer {
  opacity: 0;
  z-index: 4;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #022b38;
  border-radius: 20px;
  transition: opacity 2s ease 6s;
}

.footer.active {
  opacity: 1;
}

.wheel {
  width: 36px;
  height: 36px;
  transition: transform 2s ease, opacity 1s ease;
  opacity: 0;
  transition-delay: 6s;

  background-color: #022b38;
  border-radius: 20px;
  padding: 5px;
}

.wheel.active {
  opacity: 1;
  transform: rotate(360deg);
}

.wheel.disappear {
  opacity: 0;
  transform: rotate(180deg);
  transition: transform 2s ease, opacity 3s ease;
}

.clouds {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.cloud-big {
  position: relative;
  animation: slideRight 15s infinite linear;
}

.cloud-small {
  position: relative;
  animation: slideLeft 15s infinite linear;
}

.cloud-big.disappear {
  animation: slideLeftDisappear 3s linear forwards;
}

.cloud-small.disappear {
  animation: slideRightDisappear 3s linear forwards;
}

.begin-text.active {
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 1.5s steps(20, end);
  animation-delay: 6s;
  font-size: 14px;
}

.begin-text {
  transition: opacity 1s ease;
  opacity: 0;
  transition-delay: 6s;
  letter-spacing: 2px;
  color: white;
  padding: 10px;
  padding-left: 5px;
}

.begin-text.disappear {
  opacity: 0;
  transition: opacity 3s ease;
  animation: typing 1.5s steps(end, 40);
}

.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  transition: opacity 1s ease;
  transition-delay: 6s;
}

.arrow-container.active {
  opacity: 1;
}

.arrow-container.disappear {
  opacity: 0;
  transition: opacity 3s ease;
}

.arrow {
  width: 20px;
  height: 5px;
  opacity: 0;
  transform: scale(0.3);
  animation: move-arrow 3s ease-out infinite;
}

.arrow:first-child {
  animation: move-arrow 3s ease-out 1s infinite;
}

.arrow:nth-child(2) {
  animation: move-arrow 3s ease-out 3s infinite;
}

.arrow:before,
.arrow:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #022b38;
}

.arrow:before {
  left: 0;
  transform: skewY(30deg);
}

.arrow:after {
  right: 0;
  transform: skewY(-30deg);
}

@media only screen and (min-width: 321px) and (max-width: 379px) {
  .cloud-big {
    margin-top: -10%;
    width: 80vw;
    height: 125px;
  }

  .cloud-small {
    margin-top: 20px;
    width: 225px;
    height: 75px;
  }

  .text-journey {
    font-size: 10px;
  }

  .title-image.active {
    width: 180px;
    height: 120px;
    margin-top: 5px;
  }

  .seagull.right.active {
    width: 100px;
    top: 10%;
  }

  .seagull.right-bottom.active {
    width: 100px;
    bottom: 30%;
  }

  .bottom-image.active {
    width: 100vw;
    margin-left: 0%;
    margin-right: -5px;
  }

  .begin-text.active {
    font-size: 12px;
  }

  .footer {
    margin-top: 20px;
  }

  .wheel.active {
    width: 28px;
    height: 28px;
    margin-top: 0%;
  }

  .arrow {
    width: 16px;
    margin-top: 0%;
  }
}

@media only screen and (min-width: 380px) and (max-width: 480px) {
  .cloud-big {
    margin-top: -15%;
    width: 500px;
    height: 150px;
  }

  .cloud-small {
    margin-top: 15px;
    width: 250px;
    height: 75px;
  }

  .seagull.right.active {
    width: 40vw;
  }

  .text-journey {
    margin-top: 10px;
  }

  .title-image.active {
    width: 270px;
    height: 180px;
    margin-top: 10px;
  }

  .seagull.right.active {
    width: 130px;
    top: 10%;
  }

  .seagull.right-bottom.active {
    width: 130px;
    bottom: 30%;
  }

  .bottom-image.active {
    width: 100vw;
  }

  .footer {
    margin-top: 20px;
  }

  .wheel.active {
    width: 28px;
    height: 28px;
  }

  .begin-text.active {
    font-size: 12px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .cloud-big {
    margin-top: -15%;
    width: 100vw;
    height: 250px;
  }

  .cloud-small {
    margin-top: 10px;
    width: 300px;
    height: 100px;
  }

  .seagull.left.active {
    top: 45%;
  }

  .seagull.right.active {
    width: 180px;
    top: 15%;
    right: 2%;
  }

  .seagull.right-bottom.active {
    width: 180px;
    right: 5vw;
    bottom: 40%;
  }

  .text-journey {
    margin-top: 10px;
    font-size: 16px;
  }

  .title-image.active {
    width: 300px;
    height: 200px;
    margin-top: 10px;
  }

  .bottom-image.active {
    width: 100vw;
    margin-bottom: -120px;
  }

  .begin-text.active {
    font-size: 16px;
  }

  .footer {
    margin-top: 20px;
  }
}
