.audio-player {
  position: fixed;
  bottom: 0%;
  right: 0%;
  margin-right: 10px;
  margin-bottom: 10px;
  z-index: 99;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #022b38;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    font-size: 20px;
    cursor: pointer;
    border: none;
    color: white;
    transition: background-color 0.3s ease;
  }
}

.music-icon {
  width: 20px;
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .audio-player button {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .audio-player button {
    width: 60px;
    height: 60px;
    font-size: 24px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .audio-player button {
    width: 70px;
    height: 70px;
    font-size: 28px;
  }
}
