.faq-page {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  width: 100vw;
  color: #022b38;
  background: rgb(241, 239, 227);
  background: linear-gradient(
    0deg,
    rgba(241, 239, 227, 1) 0%,
    rgba(196, 203, 213, 1) 40%,
    rgba(228, 227, 221, 1) 100%
  );
}

.faq-title {
  margin-top: 20%;
  font-size: 40px;
}

.faq-accordion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 43px 50px 43px 50px;
  gap: 16px;
  text-align: left;
}

.panel-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  background-color: transparent;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.5px;
  border-bottom: 1px solid #022b38;
}

.panel {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);
}

.panel-para {
  font-size: 10px;
}

.panel.open {
  max-height: 1000px;
  transition: max-height 1s ease-out;
}

.arrow-faq {
  width: 15px;
  height: 15px;
}

.arrow-faq.open {
  transform: rotate(90deg);
}

.button-faq-back {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  background-color: #022b38;
  border-color: #022b38;
  font-family: 'Caudex';
  min-height: 50px;
  width: 60%;
  margin-bottom: 24px;
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .faq-accordion {
    padding: 60px 90px 60px 90px;
  }

  .faq-title {
    font-size: 72px;
  }

  .panel-header {
    font-size: 24px;
  }

  .panel-para {
    font-size: 14px;
  }

  .arrow-faq {
    width: 20px;
    height: 20px;
  }
}
