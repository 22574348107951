@font-face {
  font-family: "Caudex";
  src: url("./assets/fonts/Caudex/Caudex-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Caudex";
  src: url("./assets/fonts/Caudex/Caudex-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Title";
  src: url("./assets/fonts/Title.ttf") format("truetype");
}

@font-face {
  font-family: "Slight";
  src: url("./assets/fonts/Slight.ttf") format("truetype");
}

@font-face {
  font-family: "Cammron";
  src: url("./assets/fonts/Cammron-Regular.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-content-zooming: none;
  -webkit-user-scalable: none;
  -moz-user-scalable: none;
  -ms-user-scalable: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, textarea {
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%; 
}
