@import "../../helper/keyframe.css";

.curtain-container {
  display: flex;
  flex-direction: column;
}

.curtain-panel-left-container,
.curtain-panel-right-container {
  position: absolute;
  width: 100%;
  height: 50vh;
  z-index: 99;
}

.curtain-panel-left-container {
  left: 0;
  transform-origin: top left;
  animation: openLeftCurtain 3s forwards;
}

.curtain-panel-left-container.disappear {
  left: 0;
  transform-origin: top left;
  animation: closeLeftCurtain 3s forwards;
}

.curtain-panel-right-container {
  right: 0;
  bottom: 0;
  transform-origin: top right;
  animation: openRightCurtain 3s forwards;
}

.curtain-panel-right-container.disappear {
  right: 0;
  bottom: 0;
  transform-origin: top right;
  animation: closeRightCurtain 3s forwards;
}

.curtain-panel-left {
  border-radius: 60px;
  transform: scaleX(1);
  height: 70vh;
}

.curtain-panel-right {
  border-radius: 60px;
  transform: scaleX(-1.5);
  height: 50vh;
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .curtain-panel-left {
    transform: scale(2, 1.5);
  }

  .curtain-panel-right {
    transform: scale(-2, 1.5);
  }
}

@media only screen and (min-width: 321px) and (max-width: 379px) {
  .curtain-panel-left {
    transform: scaleX(0.8);
  }

  .curtain-panel-right {
    transform: scaleX(-0.8);
  }
}
