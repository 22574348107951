@import "../../helper/keyframe.css";

.dress-code-page {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #f5f3e7;
  height: 100vh;
  width: 100vw;
  color: #022b38;
  background-image: url(https://res.cloudinary.com/dheb80jjm/image/upload/v1716900248/wed-cyn-cyr-2/background-dress_offhoi.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.ghost-flower.right {
  position: absolute;
  right: 0;
  margin-right: -100%;
  transition: margin-right 1s ease;
}

.ghost-flower-left-container {
  position: absolute;
  left: 0;
  top: 30%;
  margin-left: -100%;
  transition: margin-left 1s ease;
  animation: sway 4s infinite;
}

.ghost-flower.left {
  margin-left: -40%;
  transform: scaleX(-1);
}

.ghost-flower.right.active {
  margin-right: 0%;
  animation: sway 4s infinite;
}

.ghost-flower-left-container.active {
  margin-left: 0%;
}

.dress-code-text-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  margin-top: 10%;
  width: 75vw;
  transform: rotate(-10deg);
  margin-left: 20px;
  margin-bottom: 20px;
}

.dress-code-text {
  font-family: "Slight";
  font-size: 48px;
  align-self: flex-start;
}

.dress-code-text.top {
  margin-left: -100%;
  padding-top: 10px;
  transition: margin-left 2s ease;
}

.dress-code-text.top.active {
  margin-left: 0;
}

.dress-code-text.bottom {
  margin-right: -100%;
  padding-right: 10px;
  transition: margin-right 2s ease;
}

.dress-code-text.bottom.active {
  margin-right: 0;
}

.dress-code-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: 3px;
}

.dress-code-image {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.dress-men-container {
  display: flex;
  justify-content: flex-end;
  transition: transform 2s ease;
  transform: translateX(-200%);
}

.dress-men-container.active {
  transform: translateX(0);
}

.dress-women-container {
  display: flex;
  transition: transform 2s ease;
  transform: translateX(200%);
}

.dress-women-container.active {
  transform: translateX(0);
}

.dress-code-text.bottom {
  display: flex;
  align-self: flex-end;
}

.pallete {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 40px;
}

.color-shape {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.pallete-text {
  display: flex;
  flex-direction: row;
  font-size: 8px;
  margin-top: 24px;
}

.pal-text {
  opacity: 0;
}

.pal-text.one {
  transition: opacity 1s ease;
}

.pal-text.two {
  transition: opacity 2s ease;
  transition-delay: 1s;
}

.pal-text.three {
  transition: opacity 2s ease;
  transition-delay: 2s;
}

.pal-text.four {
  transition: opacity 2s ease;
  transition-delay: 3s;
}

.pal-text.one.active,
.pal-text.two.active,
.pal-text.three.active,
.pal-text.four.active {
  opacity: 1;
}

.color-shape.one {
  transform: scale(0, 0);
  background-color: #000000;
  transition: transform 2s ease;
}

.color-shape.one.active {
  transform: scale(1, 1);
}

.color-shape.two {
  transform: scale(0, 0);
  background-color: #162237;
  transition: transform 2s ease;
  transition-delay: 1s;
}

.color-shape.two.active {
  transform: scale(1, 1);
}

.color-shape.three {
  transform: scale(0, 0);
  background-color: #677463;
  transition: transform 2s ease;
  transition-delay: 2s;
}

.color-shape.three.active {
  transform: scale(1, 1);
}

.color-shape.four {
  transform: scale(0, 0);
  background-color: #edd3c4;
  transition: transform 2s ease;
  transition-delay: 3s;
}

.color-shape.four.active {
  transform: scale(1, 1);
}

.straight-line {
  margin: 0px 12px 0px 12px;
}

.no-white-text {
  margin-top: 14px;
  font-size: 12px;
  font-weight: bold;
}

.attire-text {
  margin-top: 32px;
  font-size: 12px;
  font-weight: bold;
}

.dress-code {
  width: 200px;
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .dress-men,
  .dress-women {
    width: 100px;
    height: 300px;
  }

  .dress-code-text-container {
    width: 60vw;
    margin-left: 40px;
    margin-top: 10%;
    margin-bottom: 60px;
  }

  .dress-code-text {
    font-size: 72px;
  }

  .pallete {
    gap: 24px;
    margin-top: 40px;
  }

  .color-shape {
    width: 90px;
    height: 90px;
  }

  .no-white-text {
    font-size: 24px;
    margin-top: 28px;
  }

  .pallete-text {
    font-size: 16px;
    margin-top: 48px;
  }

  .attire-text {
    margin-top: 32px;
    font-size: 24px;
  }

  .straight-line {
    margin: 0px 24px 0px 24px;
  }

  .ghost-flower {
    width: 250px;
  }
}
