.bride-and-groom-slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-image: url(https://res.cloudinary.com/dheb80jjm/image/upload/v1716900246/wed-cyn-cyr-2/background-bride-and-groom_psb7fy.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.slides {
  display: flex;
  transition: transform 0.5s ease;
}

.bride-and-groom-slide {
  flex: 0 0 100%;
}

/* Styles for draggable slider */
.bride-and-groom-slider.draggable {
  cursor: grab;
}

.bride-and-groom-slider.dragging {
  cursor: grabbing;
}

/* Disable user selection during dragging */
.bride-and-groom-slider.dragging * {
  user-select: none;
}
