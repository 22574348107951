.text-area-field {
  resize: none;
  background: none;
  border: none;
  border-bottom: 2px solid #022b38;
  outline: none;
  font-family: 'Caudex', sans-serif;
  color: #022b38;
}

.form-group.error .text-area-field{
  border-bottom: 2px solid #A60421;
}
