.countdown-page {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 60vh;
  width: 100vw;
  color: white;
  background: rgb(219, 210, 182);
  background: linear-gradient(
    165deg,
    rgba(219, 210, 182, 1) 0%,
    rgba(34, 42, 74, 1) 45%,
    rgba(229, 228, 220, 1) 85%
  );
}

.date-separator {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 10px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 5px;
  margin-bottom: 32px;
  gap: 20px;
}

.on-date {
  display: flex;
  flex-direction: column;
}

.october {
  font-size: 36px;
}

.countdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  font-size: 10px;
}

.time {
  font-style: bold;
  font-size: 36px;
}

.time-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.count-flower.right {
  position: absolute;
  bottom: -10%;
  right: -20%;
  margin-right: -100%;
  transition: margin-right 1s ease;
}

.count-flower.right.active {
  margin-right: 0%;
  animation: sway 4s infinite;
}

.count-flower-container-left {
  position: absolute;
  bottom: -10%;
  left: -20%;
  margin-left: -100%;
  transition: margin-left 1s ease;
}

.count-flower-container-left.active {
  margin-left: 0%;
  animation: sway 4s infinite;
}

.count-flower.left {
  transform: scaleX(-1);
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .countdown-page {
    height: 75vh;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .date-separator {
    font-size: 16px;
    line-height: 40px;
  }

  .time {
    font-size: 64px;
  }

  .countdown {
    font-size: 16px;
  }

  .october {
    font-size: 64px;
  }

  .countdown-page {
    height: 60vh;
  }

  .count-flower.right {
    right: 0%;
    width: 250px;
  }

  .count-flower-container-left {
    left: 0%;
  }

  .count-flower.left {
    width: 250px;
  }
}
