.reservation-container {
  height: fit-content;
  min-height: fit-content;
  padding-bottom: 100px;
  width: 100%;
  position: relative;
  display: flex;
  background-image: url(https://res.cloudinary.com/dheb80jjm/image/upload/v1716900250/wed-cyn-cyr-2/rsvp-background_k818rj.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  flex-direction: column;
  align-items: center;
  color: #022b38;
}
.reservation-text {
  font-family: "Slight";
  font-size: 48px;
  transform: rotate(-10deg);
  margin-bottom: 60px;
  align-self: flex-start;
  margin-top: 15%;
  margin-left: -100%;
}

.reservation-text.active {
  animation: slideFromLeft 2s forwards;
}
.description {
  padding: 0 40px;
  margin-bottom: 27px;
  font-size: 12px;
}
.form-container {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 70%;
}
.btn-reservation {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  background-color: #022b38;
  border-color: #022b38;
  font-family: "Caudex";
  background-position: center;
  transition: background 0.8s;
  min-height: 50px;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  text-align: center;
  gap: 12px;
}

.btn-reservation.faq {
  background-color: #fff;
  color: #022b38;
  margin-top: 0px;
}

.btn-reservation.faq:hover {
  background: white radial-gradient(circle, transparent 1%, white 1%)
    center/15000%;
}

.btn-reservation.faq:active {
  background-color: #022b38;
  background-size: 100%;
  transition: background 0s;
}

.btn-reservation.active {
  animation: pop-out 2s forwards;
}

.btn-reservation:hover {
  background: #022b38 radial-gradient(circle, transparent 1%, #022b38 1%)
    center/15000%;
}

.btn-reservation:active {
  background-color: white;
  background-size: 100%;
  transition: background 0s;
}
.initial-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.reservation-footer {
  font-family: "Caudex";
  font-size: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 16px;
}
.finish-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  min-height: 40vh;
  margin: 0 40px;
  text-align: center;
}
.finish-form-name {
  font-size: 18px;
}
.finish-form-info {
  margin-top: 8px;
  margin-bottom: 16px;
}
.finish-form-btn-container {
  padding: 0 40px;
  width: 100%;
}
.btn-contact-us {
  width: calc(100% - 40px);
}

.error-snackbar {
  color: red;
  font-size: 10px;
}

.label {
  font-size: 12px;
}

@media only screen and (max-width: 320px) {
  .description {
    font-size: 12px;
  }

  .label {
    font-size: 12px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 379px) {
  .description {
    font-size: 12px;
  }

  .label {
    font-size: 12px;
  }
}

@media only screen and (min-width: 380px) and (max-width: 480px) {
  .description {
    font-size: 12px;
  }

  .label {
    font-size: 12px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .reservation-text {
    font-size: 80px;
  }

  .description {
    font-size: 20px;
  }

  .label {
    font-size: 18px;
  }
}
