.invitee-page {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(https://res.cloudinary.com/dheb80jjm/image/upload/v1716900249/wed-cyn-cyr-2/background-image_eqnkfh.jpg);
  height: 100vh;
  width: 100vw;
  color: #022b38;
}

.invitee-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.verse-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.verse-title {
  font-size: 12px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 3s ease;
  letter-spacing: 3px;
}

.verse-title.active {
  opacity: 1;
}

.verse-body {
  font-size: 8px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 2s ease;
  transition-delay: 2s;
  letter-spacing: 3px;
}

.verse-body.active {
  opacity: 1;
}

.envelope {
  position: absolute;
  cursor: pointer;
  bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.envelope-back-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.envelope-top {
  z-index: 1;
  transition: 2s;
  transform-origin: bottom;
  transform: rotateX(180deg);
}

.envelope-top.active {
  transform: rotateX(0deg);
}

.envelope-letter {
  position: absolute;
  background-color: #fff;
  bottom: 10%;
  width: 350px;
  z-index: 0;
  height: 50px;
  transition: 2s;
  text-align: center;
  text-wrap: balance;
}

.envelope-letter.active {
  z-index: 1;
  height: 400px;
}

.envelope-text {
  display: flex;
  flex-direction: column;
  height: 50%;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0px 10px 0px 10px;
  letter-spacing: 4px;
}

.dear-invitee-text {
  font-size: 24px;
}

.envelope-front-container {
  position: absolute;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.envelope-front {
  z-index: 1;
}

.seal-logo {
  position: absolute;
  z-index: 5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-seal {
  width: 100px;
  height: 100px;
}

.invitee-name {
  font-weight: bold;
  font-size: 16px;
}

@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .envelope-front {
    width: 600px;
  }

  .envelope-back-container {
    width: 600px;
  }

  .envelope-letter {
    width: 500px;
  }

  .envelope-letter.active {
    height: 600px;
  }

  .logo-seal {
    width: 150px;
    height: 150px;
  }

  .dear-invitee-text {
    font-size: 36px;
  }

  .invitee-name {
    font-size: 24px;
  }

  .verse-body {
    font-size: 18px;
  }

  .verse-title {
    font-size: 24px;
  }
}
